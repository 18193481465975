import React, { FC } from "react";
import { Transform } from "../Transform";

export const ClipboardIcon: FC = ({ ...props }) => (
  <>
    <Transform {...props} x={-0.5} y={-0.5} scale={0.0625}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 -2 25 27">
        <path
          d="M14.3,15.8c-1.4,0-2.8-0.6-3.8-1.6c-1.4-1.4-1.9-3.2-1.5-5C9,8.6,9.5,8.2,10,8.4c0.5,0.1,0.9,0.6,0.8,1.2
				c-0.2,1.1,0.1,2.3,0.9,3.1c0.9,0.9,2.1,1.2,3.2,1c0.6-0.1,1.2-0.4,1.6-0.8c0.1-0.1,0.1-0.1,0.2-0.1l2.9-2.9
				c1.3-1.3,1.3-3.5,0-4.9c-1.4-1.4-3.6-1.4-5-0.1l-1.5,1.5c0,0-0.1,0.1-0.1,0.1c-0.3,0.4-1,0.5-1.4,0.2c-0.4-0.3-0.5-1-0.2-1.4
				C11.6,5.1,11.7,5,11.8,5l1.5-1.5c2.1-2.1,5.6-2.1,7.7,0c2.2,2.2,2.2,5.6,0.1,7.8l-2.9,2.9c-0.1,0.1-0.2,0.2-0.3,0.3
				c-0.7,0.6-1.5,1-2.5,1.2C15,15.7,14.6,15.8,14.3,15.8z"
        />
        <path
          d="M7.9,22.1c-1.4,0-2.8-0.5-3.9-1.6c-2.2-2.2-2.2-5.6-0.1-7.8l2.9-2.9C7,9.8,7,9.7,7.1,9.6c0.7-0.6,1.5-1,2.5-1.2
				c1.8-0.4,3.6,0.2,5,1.5c1.4,1.4,1.9,3.2,1.5,5c-0.1,0.5-0.6,0.9-1.2,0.8c-0.5-0.1-0.9-0.6-0.8-1.2c0.2-1.1-0.1-2.3-0.9-3.1
				c-0.9-0.9-2.1-1.2-3.2-1c-0.6,0.1-1.2,0.4-1.6,0.8c-0.1,0.1-0.1,0.1-0.2,0.1l-2.9,2.9c-1.3,1.3-1.3,3.5,0,4.9
				c1.4,1.4,3.6,1.4,5,0.1l1.5-1.5c0,0,0.1-0.1,0.1-0.1c0.3-0.4,1-0.5,1.4-0.2c0.4,0.3,0.5,1,0.2,1.4c-0.1,0.1-0.2,0.2-0.3,0.3
				l-1.5,1.5C10.7,21.6,9.3,22.1,7.9,22.1z"
        />
      </svg>
    </Transform>
  </>
);
